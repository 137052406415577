import { getRandomInt } from '@lib/random';

// Approximate animation duration by controlling the number of frames required
// to complete the count. We use a range of possible frame counts from which a
// final duration is selected at random, in order that sibling elements will
// take slightly different times to complete, for a more pleasing effect.
const DURATION_FRAMES_MIN = 120;
const DURATION_FRAMES_MAX = 180;

export default class StatsGridItem extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.valueNode = this.querySelector('[data-ref="value"]');
        this._setup();
    }

    activate = () => {
        this._activate();
    };

    _setup = () => {
        const { value, suffix } = this.dataset;
        const parsedValue = Number(value);
        const durationFrames = getRandomInt(DURATION_FRAMES_MIN, DURATION_FRAMES_MAX);

        this.current = 0;
        this.target = parsedValue;
        this.step = parsedValue / durationFrames;
        this.suffix = suffix;

        this._render();
    };

    _activate = () => {
        window.requestAnimationFrame(this._count);
    };

    _count = () => {
        this.current += this.step;
        if (this.current > this.target) {
            this.current = this.target;
            this._render();
        } else {
            this._render();
            window.requestAnimationFrame(this._count);
        }
    };

    _render = () => {
        this.valueNode.innerHTML = `${Math.floor(this.current).toLocaleString()}${this.suffix}`;
    };
}

window.customElements.define('nc-stats-grid-item', StatsGridItem);
