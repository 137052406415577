export default class StatsGrid extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.observer = new IntersectionObserver(this._handleIntersection, {
            threshold: [0, 0.25, 0.5, 0.75, 1],
        }).observe(this);
    }

    disconnectedCallback() {
        this.observer.disconnect();
    }

    _handleIntersection = ([entry], observer) => {
        if (entry.intersectionRatio >= 0.5) {
            this._activate();
            observer.disconnect();
        }
    };

    _activate = () => {
        this.classList.add('is-active');
        this.querySelectorAll('nc-stats-grid-item').forEach((node) => node.activate());
    };
}

window.customElements.define('nc-stats-grid', StatsGrid);
