import lottie from 'lottie-web';

const BACKGROUND_COLORS = ['dark-green', 'pink', 'light-green', 'orange', 'yellow'];

const className = (color) => `brand-animation--background-color-${color}`;
const matchMediaPortrait = window.matchMedia('(orientation: portrait');

export default class BrandAnimation extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.playerNode = this.querySelector('[data-ref="player"]');
        this.textNodes = this.querySelectorAll('[data-ref="text"]');
        this.slideNodes = this.querySelectorAll('[data-ref="slide"]');

        this.observer = new IntersectionObserver(this._handleIntersection, {
            threshold: [0.6],
        });
        this.slideNodes.forEach((slideNode) => this.observer.observe(slideNode));

        const { srcLandscape, srcPortrait } = this.dataset;
        this.animation = lottie.loadAnimation({
            container: this.playerNode,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: matchMediaPortrait.matches ? srcPortrait : srcLandscape,
            rendererSettings: {
                viewBoxOnly: true,
            },
        });
    }

    _activate = (slideNode) => {
        const idx = [...this.slideNodes].findIndex((n) => n === slideNode);
        const { backgroundColor, startFrame, endFrame } = slideNode.dataset;
        this._activateBackgroundColor(backgroundColor);
        this._activateText(idx);
        this._activateAnimation(startFrame, endFrame);
    };

    _activateAnimation = (startFrame, endFrame) => {
        this.animation.resetSegments();
        this.animation.playSegments([Number(startFrame), Number(endFrame)], true);
    };

    _activateBackgroundColor = (backgroundColor) => {
        BACKGROUND_COLORS.forEach((color) => this.classList.remove(className(color)));
        this.classList.add(className(backgroundColor));
    };

    _activateText = (idx) => {
        this.textNodes.forEach((textNode, i) =>
            i === idx
                ? textNode.classList.add('is-active')
                : textNode.classList.remove('is-active'),
        );
    };

    _handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this._activate(entry.target);
            }
        });
    };
}

window.customElements.define('nc-brand-animation', BrandAnimation);
