import Player from '@vimeo/player';

export default class VideoHero extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.modalTriggerNode = this.querySelector('[data-ref="modal-trigger"]');
        this.modalNode = this.querySelector('[data-ref="modal"]');
        this.modalInnerNode = this.querySelector('[data-ref="inner"]');
        this.embedNode = this.querySelector('[data-ref="embed"]');

        this.addEventListener('click', this._handleClick);
    }

    _handleClick = (event) => {
        if (event.target.closest('[data-ref="scroll-hint"]')) {
            this._handleScrollHintNodeClick();
        } else if (event.target.closest('[data-ref="modal-trigger"]')) {
            this._handleModalTriggerClick();
        } else if (event.target.closest('[data-ref="close"]')) {
            this._handleModalCloseClick();
        }
    };

    _handleDocumentClick = (event) => {
        // Handle click outside inner node
        if (!this.modalInnerNode.contains(event.target)) {
            this._closeModal();
        }
    };

    _handleDocumentKeyUp = (event) => {
        if (event.key === 'Escape') this._closeModal();
    };

    _handleModalCloseClick = () => {
        this._closeModal();
    };

    _handleModalTriggerClick = () => {
        this._openModal();
    };

    _handleScrollHintNodeClick = () => {
        // Scroll bottom of component to top of viewport
        const { bottom } = this.getBoundingClientRect();
        const to = window.scrollY + bottom;
        window.scrollTo(0, to);
    };

    _closeModal = () => {
        this.modalTriggerNode.setAttribute('aria-expanded', 'false');
        this.modalNode.setAttribute('aria-expanded', 'false');

        document.removeEventListener('click', this._handleDocumentClick);
        document.removeEventListener('keyup', this._handleDocumentKeyUp);
        if (this.callingNode) this.callingNode.focus();
        document.body.classList.remove('has-open-modal');

        if (this.player) this.player.pause();
    };

    _openModal = () => {
        this.modalTriggerNode.setAttribute('aria-expanded', 'true');
        this.modalNode.setAttribute('aria-expanded', 'true');

        this.callingNode = document.activeElement;
        document.addEventListener('keyup', this._handleDocumentKeyUp);
        this.modalNode.querySelector('button').focus();
        document.body.classList.add('has-open-modal');

        // XXX: Don't bind handler until the click that triggered this open
        // has finished propagating
        window.setTimeout(() => document.addEventListener('click', this._handleDocumentClick), 0);

        this.player = new Player(this.embedNode);
        this.player.play();
    };
}

window.customElements.define('nc-video-hero', VideoHero);
