import { supportsPassiveEvents } from 'detect-passive-events';

const FORWARDS = 1;
const BACKWARDS = -1;

export default class Timeline extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.scrollableNode = this.querySelector('[data-ref="scrollable"]');
        this.itemNodes = this.querySelectorAll('[data-ref="item"]');
        this.scrollNextNode = this.querySelector('[data-ref="scroll-next"]');
        this.scrollPrevNode = this.querySelector('[data-ref="scroll-prev"]');

        this.scrollableNode.addEventListener(
            'scroll',
            this._handleScroll,
            supportsPassiveEvents ? { passive: true } : false,
        );
        this.scrollNextNode.addEventListener('click', this._handleScrollNextClick);
        this.scrollPrevNode.addEventListener('click', this._handleScrollPrevClick);

        this._handleScroll();
    }

    _handleScroll = () => {
        // Enable/disable prev/next buttons based on current scroll position
        const { width } = this.scrollableNode.getBoundingClientRect();
        const { scrollLeft, scrollWidth } = this.scrollableNode;
        this.scrollPrevNode.disabled = scrollWidth <= width || scrollLeft === 0;
        this.scrollNextNode.disabled = scrollWidth <= width || scrollLeft >= scrollWidth - width;
    };

    _handleScrollNextClick = () => {
        this._scroll(FORWARDS);
    };

    _handleScrollPrevClick = () => {
        this._scroll(BACKWARDS);
    };

    _scroll = (direction) => {
        // Simplified scroll state management – measure width of first item
        // and scroll by that amount. Scroll Snap takes care of resulting
        // alignment.
        const itemNode = this.querySelector('[data-ref="item"]');
        const { width } = itemNode.getBoundingClientRect();
        if (direction === FORWARDS) {
            this.scrollableNode.scrollLeft += width;
        } else if (direction === BACKWARDS) {
            this.scrollableNode.scrollLeft -= width;
        }
    };
}

window.customElements.define('nc-timeline', Timeline);
