export default class Header extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.toggleNode = this.querySelector('[data-ref="toggle"]');
        this.navNode = this.querySelector('[data-ref="nav"]');

        this.toggleNode.addEventListener('click', this._handleToggleClick);
        this._observeResize();
    }

    _handleResize = ([entry]) => {
        const height = entry.borderBoxSize[0].blockSize;
        document.documentElement.style.setProperty('--header-height', `${height}px`);
    };

    _handleToggleClick = () => {
        this.toggleNode.setAttribute(
            'aria-expanded',
            this.toggleNode.getAttribute('aria-expanded') !== 'true',
        );
    };

    _observeResize = () => {
        new window.ResizeObserver(this._handleResize).observe(this);
    };
}

window.customElements.define('nc-header', Header);
