export default class PlaceProfile extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.headerNode = this.querySelector('[data-ref="header"]');

        this._observeResize();
    }

    _handleResize = ([entry]) => {
        const height = entry.borderBoxSize[0].blockSize;
        this.style.setProperty('--profile-header-height', `${height}px`);
    };

    _observeResize = () => {
        new window.ResizeObserver(this._handleResize).observe(this.headerNode);
    };
}

window.customElements.define('nc-place-profile', PlaceProfile);
