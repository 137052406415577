export default class ImageBlock extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.annotationTriggerNodes = this.querySelectorAll('[data-ref="trigger"]');
        this.annotationContentNodes = this.querySelectorAll('[data-ref="content"]');

        this.addEventListener('click', this._handleClick);
        document.addEventListener('keyup', this._handleDocumentKeyUp);
    }

    disconnectedCallback() {
        document.removeEventListener('keyup', this._handleDocumentKeyUp);
    }

    _closeAnnotations = () => {
        this.annotationTriggerNodes.forEach((node) => node.setAttribute('aria-expanded', 'false'));
        this.annotationContentNodes.forEach((node) => node.setAttribute('aria-expanded', 'false'));
    };

    _handleClick = (event) => {
        if (event.target.closest('[data-ref="trigger"]')) {
            this._handleAnnotationTriggerClick(event);
        } else if (event.target.closest('[data-ref="close"]')) {
            this._handleAnnotationCloseClick(event);
        } else {
            this._closeAnnotations();
        }
    };

    _handleAnnotationCloseClick = () => {
        this._closeAnnotations();
    };

    _handleDocumentKeyUp = (event) => {
        if (event.key === 'Escape') {
            this._closeAnnotations();
        }
    };

    _handleAnnotationTriggerClick = (event) => {
        this._closeAnnotations();
        const annotationTriggerNode = event.target.closest('[data-ref="trigger"]');
        const { id } = annotationTriggerNode.dataset;
        this.annotationTriggerNodes.forEach(
            (node) => node.dataset.id === id && node.setAttribute('aria-expanded', 'true'),
        );
        this.annotationContentNodes.forEach(
            (node) => node.dataset.id === id && node.setAttribute('aria-expanded', 'true'),
        );
    };
}

window.customElements.define('nc-image-block', ImageBlock);
