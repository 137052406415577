export function getRandomInt(min, max) {
    // Return a random integer between the specified values. The value is no
    // lower than min (or the next integer greater than min if min isn't an
    // integer), and is less than (but not equal to) max.
    //
    // source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
}
