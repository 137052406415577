import { FETCH_OPTS } from '@lib/constants';

export default class EntryGrid extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', this._handleClick);
    }

    _handleClick = (event) => {
        if (event.target.closest('[data-ref="trigger"]')) {
            this._handleTriggerClick(event);
        }
    };

    _handleTriggerClick = (event) => {
        event.preventDefault();
        const triggerNode = event.target.closest('[data-ref="trigger"]');
        const url = triggerNode.href;
        this.setAttribute('aria-busy', 'true');
        fetch(url, FETCH_OPTS)
            .then((response) => response.text())
            .then((html) => this._renderResponse(html));
    };

    _renderResponse = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        this.replaceWith(doc.firstChild);
    };
}

window.customElements.define('nc-entry-grid', EntryGrid);
